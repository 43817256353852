<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item label="销售区域:" prop="area_id">
        <sale-area-select-tree
          @set-sale-select-id="selectArea"
        ></sale-area-select-tree>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-id="clientSelect"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <!-- <el-form-item label="" prop="">
        <el-button>打印</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button>导出</el-button>
      </el-form-item> -->
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      :span-method="firstSpanMethod"
      show-summary
      :summary-method="summaryFunction"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="" width="50">
        <template #default="{ row }">
          {{ row.idx }}
        </template>
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { arrearsList } from '@/api/warning'
  import { departmentList } from '@/api/performance'
  import { getStaffList } from '@/api/setPrice'
  import SaleAreaSelectTree from '@/baseComponents/saleAreaSelectTree'
  import ClientSearch from '@/baseComponents/clientSearch'
  export default {
    name: 'Arrears',
    components: { SaleAreaSelectTree, ClientSearch },
    data() {
      return {
        loading: false,
        bmList: [],
        staffList: [],
        list: [],
        checkList: [
          '客户名称',
          '老板姓名',
          '电话',
          '待清欠总额',
          '超期欠款单',
          '初始欠款金额',
          '剩余欠款金额',
          '初始欠款日期',
          '欠款天数',
        ],
        columns: [
          {
            order: 1,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 2,
            label: '老板姓名',
            prop: 'boss',
            width: '',
          },
          {
            order: 3,
            label: '电话',
            prop: 'mobile',
            width: '',
          },
          {
            order: 4,
            label: '待清欠总额',
            prop: 'owed_qian_money',
            width: '',
          },
          {
            order: 5,
            label: '超期欠款单',
            prop: 'order_ori_code',
            width: '',
          },
          {
            order: 6,
            label: '初始欠款金额',
            prop: 'ori_qian_money',
            width: '',
          },
          {
            order: 7,
            label: '剩余欠款金额',
            prop: 'qian_money',
            width: '',
          },
          {
            order: 8,
            label: '初始欠款日期',
            prop: 'create_at',
            width: '',
          },
          {
            order: 9,
            label: '欠款天数',
            prop: 'arrears_day',
            width: '',
          },
        ],
        form: {
          pageNo: 1, //当前页
          pageSize: 10, //页条数
          line_id: '', //线路ID
          cust_id: '', //客户名、老板、电话
          area_id: '', //区域
          user_id: '', //业务员ID
          depart_id: '', //部门ID
        },
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        spanArr: [],
        totalAll: {},
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {
      this.fetchData().then((e) => {
        this.setrowspans()
      })
      departmentList().then((res) => {
        this.bmList = res.data.list
      })
      getStaffList().then((res) => {
        this.staffList = res.data
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount, msg, ext } = await arrearsList(this.form)
        console.log(data, totalCount)
        let this_ = this
        let idx = 1
        data.forEach((item, index) => {
          console.log(data[index])
          if (index !== data.length - 1) {
            if (data[index].cust_id != data[index + 1].cust_id) {
              item.idx = idx
              idx++
            } else {
              item.idx = idx
            }
          } else {
            item.idx = 10
          }
        })
        this.list = data
        this.totalAll = ext
        this.total = totalCount
        this.loading = false
        this.setrowspans()
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      selectArea(val) {
        console.log(val)
        this.form.area_id = val
      },
      clientSelect(val) {
        this.form.cust_id = val
      },
      // 合并列
      setrowspans() {
        let contactDot = 0
        let spanArr = []
        this.list.forEach((item, index) => {
          if (index === 0) {
            console.log(spanArr)
            spanArr.push(1)
          } else {
            if (item.cust_id === this.list[index - 1].cust_id) {
              spanArr[contactDot] += 1
              spanArr.push(0)
            } else {
              contactDot = index
              spanArr.push(1)
            }
          }
        })
        this.spanArr = spanArr
      },
      // 表格合计fun
      summaryFunction(param) {
        // const { columns, data } = param
        // // 需要合计的下标
        // let i = [4, 6, 7]
        let sums = [
          '合计\n总计',
          '',
          '',
          '',
          this.totalAll.owed_qian_money_page +
            '元 \n ' +
            this.totalAll.owed_qian_money_total +
            '元',
          '',
          this.totalAll.ori_qian_money_page +
            '元 \n ' +
            this.totalAll.ori_qian_money_total +
            '元',
          this.totalAll.qian_money_page +
            '元 \n ' +
            this.totalAll.qian_money_total +
            '元',
        ]
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        // 加合计 总合计  777元 / 999元
        // columns.forEach((col, idx) => {
        //   i.forEach((n) => {
        //     if (idx == n) {
        //       console.log(col.property)
        //       const val = data.map((item) => Number(item[col.property]))
        //       console.log(val)
        //       if (!val.every((value) => isNaN(value))) {
        //         const a = val.reduce((total, num) => {
        //           if (!isNaN(total)) {
        //             return total + num
        //           }
        //         })
        //         sums[n] = a.toFixed(2) + ' 元'
        //       }
        //     }
        //   })
        //   if (idx == 0) {
        //     sums[idx] = '合计'
        //   }
        // })
        // console.log('sss', sums)
        return sums
      },
      firstSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (
          columnIndex === 0 ||
          columnIndex === 1 ||
          columnIndex === 2 ||
          columnIndex === 3 ||
          columnIndex === 4
        ) {
          if (this.spanArr[rowIndex]) {
            return {
              rowspan: this.spanArr[rowIndex],
              colspan: 1,
            }
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            }
          }
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
